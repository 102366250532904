import axios from 'axios';
import { getAuthorizationTokens } from '@loggi/authentication-lib';
import * as Sentry from '@sentry/browser';
import { APP_NAME } from './constants';

export const DEFAULT_REQUEST_TIMEOUT = 60 * 1000;

function createApi() {
  const baseURL = `${process.env.REACT_APP_LOGGI_WEB_URL}`;
  const axiosConfig = {
    baseURL,
    timeout: DEFAULT_REQUEST_TIMEOUT
  };

  return axios.create(axiosConfig);
}

const apiRest = createApi();

apiRest.interceptors.response.use(null, error => {
  if (error.response?.config) {
    const request = {
      url: error.response.config.url || ''
    };

    if (error.response.config.data !== undefined) {
      try {
        request.data = JSON.parse(error.response.config.data);
      } catch (e) {
        request.data = error.response.config.data;
      }
    } else {
      request.data = error.response.config.params || '';
    }

    const response = {
      status: error.response.request?.status || '',
      data: error.response.data || ''
    };

    Sentry.withScope(scope => {
      scope.setExtras({
        request,
        response
      });
    });
  }

  return Promise.reject(error);
});

const headers = async selectedDistributionCenterId => {
  const { idToken } = await getAuthorizationTokens();

  const requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    AppVersion: process.env.REACT_APP_VERSION,
    Authorization: `Bearer ${idToken}`
  };

  if (selectedDistributionCenterId) {
    requestHeaders.SelectedDistributionCenterId = selectedDistributionCenterId;
  }

  return requestHeaders;
};

const identifyPackagesToReceive = async barcode => {
  return apiRest.get(`/wms/api/v1/get-receive-info/${barcode}`, {
    headers: await headers()
  });
};

const movePackagesForCustodyTransfer = async (
  packageIds,
  destinationUnitLoadLicensePlate,
  sourceUnitLoadLicensePlate
) => {
  return apiRest.post(
    'wms/api/v1/move-packages-custody-transfer',
    {
      package_ids: packageIds,
      destination_unit_load_license_plate: destinationUnitLoadLicensePlate,
      source_unit_load_license_plate: sourceUnitLoadLicensePlate
    },
    {
      headers: await headers()
    }
  );
};

const getOrCreateUnitLoad = async (licensePlate, name) => {
  return apiRest.post(
    'wms/api/v1/get-create-unitload',
    {
      license_plate: licensePlate,
      name
    },
    {
      headers: await headers()
    }
  );
};

const caraCrachaForDriver = async (driverId, itineraryId, licensePlate) => {
  return apiRest.get(
    `wms/api/v2/cara-cracha-for-driver/?driver_pk=${driverId}&itinerary_pk=${itineraryId}&license_plate=${licensePlate}`,
    {
      headers: await headers()
    }
  );
};

const getDestinationStatusPplt = async barcode => {
  return apiRest.get(
    `wms/api/v1/unit-load-waiting-confirmation/?barcode=${barcode}`,
    {
      headers: await headers()
    }
  );
};

const sortUnitLoad = async (licensePlate, readBarcode) => {
  return apiRest.post(
    'wms/api/v1/sort-unit-load',
    {
      package_barcode: readBarcode,
      sorting_context_lpn: licensePlate
    },
    {
      headers: await headers()
    }
  );
};

const movePackage = async (
  unitLoadLpn,
  packageBarcode,
  sortingDecisionLpn,
  sortingContextLpn,
  sideEffectParams = {},
  moveRecordParams = {}
) => {
  const sideEffectsParamsWithAppName = {
    ...sideEffectParams,
    applicationName: APP_NAME
  };

  return apiRest.post(
    'wms/api/v1/move-package',
    {
      package_barcode: packageBarcode,
      unit_load_lpn: unitLoadLpn,
      sorting_decision_lpn: sortingDecisionLpn,
      sorting_context_lpn: sortingContextLpn,
      side_effect_params: sideEffectsParamsWithAppName,
      move_record_params: moveRecordParams
    },
    {
      headers: await headers()
    }
  );
};

const movePackagesBulk = async ({
  packageIds,
  destinationUnitLoadLicensePlate,
  receiver,
  sortingContextLpn,
  sideEffectParams = {}
}) => {
  const sideEffectsParamsWithAppName = {
    ...sideEffectParams,
    applicationName: APP_NAME
  };

  return apiRest.post(
    'wms/api/v1/move-packages-bulk',
    {
      package_ids: packageIds,
      destination_unit_load_license_plate: destinationUnitLoadLicensePlate,
      sorting_context_lpn: sortingContextLpn,
      receiver,
      side_effect_params: sideEffectsParamsWithAppName
    },
    {
      headers: await headers()
    }
  );
};

const dispatchBag = async (
  bagSeal,
  geo,
  dynamicDispatcher,
  confirmationStatus,
  isNuvemApi
) => {
  const lng = geo.coords.longitude;
  const lat = geo.coords.latitude;

  return apiRest.post(
    'wms/api/v1/dispatch',
    {
      bag_seal: bagSeal,
      lat,
      lng,
      dynamic_dispatcher: dynamicDispatcher,
      confirmation_status: confirmationStatus,
      is_nuvem_api: isNuvemApi
    },
    {
      headers: await headers()
    }
  );
};

const unitize = async (sourceLpn, destinationLpn, sortingContextLpn) => {
  return apiRest.post(
    'wms/api/v1/unitize',
    {
      source_lpn: sourceLpn,
      destination_lpn: destinationLpn,
      sorting_context_lpn: sortingContextLpn
    },
    {
      headers: await headers()
    }
  );
};

const getSortingContext = async (barcode, disableFetchDecisions = false) => {
  return apiRest.get('wms/api/v1/sorting-context', {
    params: {
      license_plate: barcode,
      disable_fetch_decisions: disableFetchDecisions
    },
    headers: await headers()
  });
};

const startTransfer = async (transferId, vehicleLicensePlate) => {
  return apiRest.post(
    'wms/api/v1/start-transfer',
    {
      transfer_id: transferId,
      vehicle_license_plate: vehicleLicensePlate
    },
    {
      headers: await headers()
    }
  );
};

const getUnitLoad = async licensePlate => {
  return apiRest.get('wms/api/v1/unit-load', {
    params: { license_plate: licensePlate },
    headers: await headers()
  });
};

const postCancelAllocation = async (
  driverId,
  pickupId,
  reason,
  loggiUserId
) => {
  return apiRest.post(
    'offer/cancelpickup',
    {
      driver_id: driverId,
      pickup_id: pickupId,
      reason,
      loggi_user_id: loggiUserId
    },
    {
      headers: await headers()
    }
  );
};

const unitloadStartProcessingDeadlineDates = async licensePlate => {
  return apiRest.get('wms/api/v2/unit-load-start-processing-deadline-dates', {
    params: { license_plate: licensePlate },
    headers: await headers()
  });
};

const getUnitLoadMetrics = async licensePlate => {
  return apiRest.get('wms/api/v1/unit-load-metrics', {
    params: { license_plate: licensePlate },
    headers: await headers()
  });
};

const logLogicalMismatches = async (
  lat,
  lng,
  sortingContextLicensePlate,
  unitLoadLicensePlate,
  packages
) => {
  return apiRest.post(
    'wms/api/v2/logs/logical-mismatches',
    {
      lat,
      lng,
      sorting_context_license_plate: sortingContextLicensePlate,
      unit_load_license_plate: unitLoadLicensePlate,
      packages_in_logical_mismatch: packages
    },
    {
      headers: await headers()
    }
  );
};

const deliverBagRecord = async (bagSeal, itineraryId, driverId) => {
  return apiRest.post(
    'wms/api/v1/deliver-bag-record',
    {
      bag_seal: bagSeal,
      itinerary_id: itineraryId,
      driver_id: driverId
    },
    {
      headers: await headers()
    }
  );
};

const pickupPhotoComparison = async deliverBagRecordId => {
  return apiRest.post(
    'security/pickup-photo-comparison',
    {
      deliver_bag_record_id: deliverBagRecordId
    },
    {
      headers: await headers()
    }
  );
};

const postProcessPhotoComparison = async PhotoComparisonId => {
  return apiRest.post(
    'security/post-process-photo-comparison',
    {
      photo_comparison_id: PhotoComparisonId
    },
    {
      headers: await headers()
    }
  );
};

const physicalEvidenceStatuses = async () => {
  return apiRest.get('wms/api/v2/physical-evidence-statuses', {
    headers: await headers()
  });
};

const editPackageStatus = async (packageCheckId, newStatus, notes) => {
  return apiRest.post(
    'wms/api/v1/edit-package-status',
    {
      check_id: packageCheckId,
      notes,
      new_status_code: newStatus
    },
    {
      headers: await headers()
    }
  );
};

const createPackageDirectionChange = async (
  packageId,
  directionValue,
  reasonValue,
  notes
) => {
  return apiRest.post(
    'wms/api/v1/create-package-direction-change',
    {
      package_direction_change: {
        package_id: packageId,
        direction: directionValue,
        reason: reasonValue,
        comments: notes,
        system: 'xd-app'
      }
    },
    {
      headers: await headers()
    }
  );
};

const cognitoDistributionCenters = async () => {
  return apiRest.get('wms/api/v2/cognito-distribution-centers', {
    headers: await headers()
  });
};

const getDistributeInfo = async ({ seal, selectedDistributionCenterId }) => {
  return apiRest.get('wms/api/v1/get-distribute-info', {
    params: { seal },
    headers: await headers(selectedDistributionCenterId)
  });
};

const searchTransfers = async ({
  statuses,
  searchTerm,
  itemsPerPage,
  page,
  ids,
  selectedDistributionCenterId
}) => {
  return apiRest.get('wms/api/v1/search-transfers', {
    params: {
      statuses,
      items_per_page: itemsPerPage,
      search_term: searchTerm,
      page,
      ids,
      selected_distribution_center_id: selectedDistributionCenterId
    },
    headers: await headers()
  });
};

const addBagToTransfer = async ({ bagSeal, transferId }) => {
  return apiRest.post(
    'wms/api/v1/add-bag-to-transfer',
    {
      bag_seal: bagSeal,
      transfer_id: transferId
    },
    {
      headers: await headers()
    }
  );
};

const getReturnCauseList = async barcode => {
  return apiRest.get('wms/api/v1/get-return-cause-list', {
    params: { barcode },
    headers: await headers()
  });
};

const logActivityTracking = async ({
  processName,
  activityName,
  timeSpentInMs,
  startDate,
  endDate,
  distributionCenterId,
  userEmail,
  processId
}) => {
  return apiRest.post(
    'wms/api/v2/logs/activity-tracking',
    {
      operational_process: processName,
      activity: activityName,
      time_spent_milliseconds: timeSpentInMs,
      started_at_time: startDate,
      finished_at_time: endDate,
      distribution_center_id: distributionCenterId,
      loggi_user_email: userEmail,
      process_id: processId
    },
    {
      headers: await headers()
    }
  );
};

const getInfoFromPackageIdentifier = async ({
  packageIdentifier,
  queryOriginalBarcode = false
}) => {
  return apiRest.get(
    `minhaloggi/api/v1/get-info-from-package-identifier/${packageIdentifier}`,
    {
      headers: await headers(),
      params: {
        query_original_barcode: queryOriginalBarcode
      }
    }
  );
};

const getLoggiLabel = async ({ packageIdentifier, companyId }) => {
  return apiRest.post(
    'minhaloggi/api/v1/get-label-from-loggi-key',
    {
      package_identifier: packageIdentifier,
      company_id: companyId
    },
    {
      headers: await headers()
    }
  );
};

const getPostingCards = async distributionCenterId => {
  return apiRest.get(`/wms/api/v1/get-posting-cards/${distributionCenterId}`, {
    headers: await headers()
  });
};

const getOrCreateRedispatchLabel = async ({
  packageIdentifier,
  isEmergency,
  distributionCenterId,
  postingCardNumber,
  packageId
}) => {
  return apiRest.post(
    '/wms/api/v1/get-or-create-redispatch-label',
    {
      package_id: packageId,
      package_identifier: packageIdentifier,
      is_emergency: isEmergency,
      distribution_center_id: distributionCenterId,
      posting_card_number: postingCardNumber,
      application_name: APP_NAME
    },
    {
      headers: await headers()
    }
  );
};

const getContentDeclaration = async ({ loggiKey }) => {
  return apiRest.get(`/wms/api/v1/content-declaration`, {
    params: {
      loggi_key: loggiKey
    },
    headers: await headers()
  });
};

const getTrips = async (
  dateTimestamp,
  vehicleLicensePlate,
  distributionCenterRoutingCode
) => {
  return apiRest.get(`/wms/api/v1/trips`, {
    params: {
      date_ts: dateTimestamp,
      vehicle_license_plate: vehicleLicensePlate,
      distribution_center_routing_code: distributionCenterRoutingCode
    },
    headers: await headers()
  });
};

const getTripStats = async tripId => {
  return apiRest.get(`/wms/api/v1/trips/${tripId}/cargo/stats`, {
    headers: await headers()
  });
};

const addCargoToTransferByTrip = async ({
  tripId,
  licensePlate,
  sourceRoutingCode,
  forceOverrideToken,
  destinationRoutingCode,
  returnRelatedCargo = false,
  addedInBulk = false
}) => {
  return apiRest.post(
    `wms/api/v1/trips/${tripId}/rc/${sourceRoutingCode}/cargo/add`,
    {
      license_plate: licensePlate,
      force_override_token: forceOverrideToken,
      destination_routing_code: destinationRoutingCode,
      include_related_cargo: returnRelatedCargo,
      was_added_in_bulk: addedInBulk
    },
    {
      headers: await headers()
    }
  );
};

const cancelTrip = async ({ tripId, sourceRoutingCode }) => {
  return apiRest.post(
    `wms/api/v1/trips/${tripId}/rc/${sourceRoutingCode}/cancel`,
    {},
    {
      headers: await headers()
    }
  );
};

const cancelDistribution = async ({ distributionUnitLoadId, routingCode }) => {
  return apiRest.post(
    `wms/api/v1/distribute/${distributionUnitLoadId}/rc/${routingCode}/cancel`,
    {},
    {
      headers: await headers()
    }
  );
};

const removeCargo = async ({ tripId, sourceRoutingCode, licensePlates }) => {
  return apiRest.post(
    `wms/api/v1/trips/${tripId}/rc/${sourceRoutingCode}/cargo/remove`,
    {
      license_plates: licensePlates
    },
    {
      headers: await headers()
    }
  );
};

const getTripCargo = async ({ tripId, sourceRoutingCode }) => {
  return apiRest.get(
    `wms/api/v1/trips/${tripId}/rc/${sourceRoutingCode}/cargo`,
    {
      headers: await headers()
    }
  );
};

const getNetworkDestinations = async distributionCenterRoutingCode => {
  return apiRest.get(
    `/wms/api/v1/network/destination/${distributionCenterRoutingCode}`,
    {
      headers: await headers()
    }
  );
};

const getDistributionUnitLoadCargo = async distributionUnitLoadId => {
  return apiRest.get(
    `/wms/api/v1/distribute/distribution-unit-load/${distributionUnitLoadId}/cargo`,
    {
      headers: await headers()
    }
  );
};

const removeDistributionUnitLoadCargo = async ({
  distributionUnitLoadId,
  sourceRoutingCode,
  licensePlates
}) => {
  return apiRest.post(
    `/wms/api/v1/distribute/${distributionUnitLoadId}/rc/${sourceRoutingCode}/cargo/remove`,
    {
      license_plates: licensePlates
    },
    {
      headers: await headers()
    }
  );
};

const distributionUnitLoad = async ({
  vehicleLicensePlate,
  originRoutingCode,
  destinations
}) => {
  return apiRest.post(
    `/wms/api/v1/distribute/distribution-unit-load`,
    {
      vehicle_license_plate: vehicleLicensePlate,
      origin_routing_code: originRoutingCode,
      stops: destinations
    },
    {
      headers: await headers()
    }
  );
};

const distributionAddCargo = async ({
  distributionUnitLoadId,
  licensePlate,
  routingCode,
  destinationRoutingCode = '',
  overrideToken = '',
  returnRelatedCargo = false
}) => {
  return apiRest.post(
    `wms/api/v1/distribute/${distributionUnitLoadId}/rc/${routingCode}/cargo/add`,
    {
      license_plate: licensePlate,
      force_override_token: overrideToken,
      destination_routing_code: destinationRoutingCode,
      include_related_cargo: returnRelatedCargo
    },
    {
      headers: await headers()
    }
  );
};

const startTransfers = async ({
  distributionUnitLoadId,
  routingCode,
  vehicleLicensePlate
}) => {
  return apiRest.post(
    `/wms/api/v1/distribute/start-distribution-ul-transfers`,
    {
      distribution_unit_load_id: distributionUnitLoadId,
      origin_routing_code: routingCode,
      vehicle_license_plate: vehicleLicensePlate
    },
    {
      headers: await headers()
    }
  );
};

const startTripTransfers = async ({
  tripId,
  sourceRoutingCode,
  transferIds
}) => {
  return apiRest.post(
    `wms/api/v1/trips/${tripId}/rc/${sourceRoutingCode}/start`,
    {
      transfer_ids: transferIds
    },
    {
      headers: await headers()
    }
  );
};

const declarePackageDamage = async (
  packageId,
  damageDescription,
  images,
  damageCause,
  physicalDamage,
  processLocation,
  distributionCenterId
) => {
  const formData = new FormData();
  formData.append('package_id', packageId);
  formData.append('description', damageDescription);
  formData.append('damage_cause', damageCause);
  formData.append('current_status', physicalDamage);
  formData.append('process_location', processLocation);
  formData.append('distribution_center_id', distributionCenterId);

  images.forEach(image => {
    formData.append('images', image, image.name);
  });

  return apiRest.post(
    'antifraud/fraud/damaged-package-declaration/',
    formData,
    {
      headers: {
        ...(await headers()),
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

const getDeclarePackageDamage = async packageId => {
  return apiRest.get(
    `antifraud/fraud/damaged-package-declaration/${packageId}/`,
    {
      headers: await headers()
    }
  );
};

const validDamagedPackageDeclaration = async packageId => {
  return apiRest.get(
    `antifraud/fraud/damaged-package-declaration/valid/${packageId}/`,
    {
      headers: await headers()
    }
  );
};

const declareDamagedPackageV2 = async (
  packageId,
  distributionCenterId,
  images,
  physicalState,
  isImageLabelIdentified,
  productLostStatusType,
  damageLocation
) => {
  const requestBody = {
    package_id: packageId,
    distribution_center_id: distributionCenterId,
    package_damage: physicalState && physicalState.package,
    product_damage: physicalState && physicalState.product,
    is_image_label_identified: isImageLabelIdentified,
    product_lost_status_type: productLostStatusType,
    declared_damage_location: damageLocation,
    images
  };

  return apiRest.post(
    'antifraud/fraud/v2/damaged-package-declaration/',
    requestBody,
    {
      headers: await headers()
    }
  );
};

const declareDamagedPackageV3 = async props => {
  const {
    packageId,
    distributionCenterId,
    images,
    physicalState,
    isImageLabelIdentified,
    productLostStatusType,
    packagingQualityStatusType,
    damageLocation
  } = props;

  const requestBody = {
    package_id: packageId,
    distribution_center_id: distributionCenterId,
    package_damage: physicalState && physicalState.package,
    product_damage: physicalState && physicalState.product,
    is_image_label_identified: isImageLabelIdentified,
    product_lost_status_type: productLostStatusType,
    packaging_quality_status_type: packagingQualityStatusType,
    declared_damage_location: damageLocation,
    images
  };

  return apiRest.post(
    'antifraud/fraud/v2/damaged-package-declaration/',
    requestBody,
    {
      headers: await headers()
    }
  );
};

const generateDamagedPackagePresignedUrlsForUpload = async (
  packageId,
  distributionCenterId,
  packageQuantity,
  labelQuantity
) => {
  const requestBody = {
    package_id: packageId,
    distribution_center_id: distributionCenterId,
    photo_type_counts_dict: {
      DAMAGED_PACKAGE_PHOTO_TYPE_PACKAGE: packageQuantity,
      DAMAGED_PACKAGE_PHOTO_TYPE_LABEL: labelQuantity
    }
  };

  return apiRest.post(
    `antifraud/fraud/damaged-package-declaration/generate-presigned-urls-for-upload/`,
    requestBody,
    {
      headers: await headers()
    }
  );
};

const matchImageWithPackage = async (packageId, image) => {
  const formData = new FormData();
  formData.append('package_id', packageId);
  formData.append('images', image, image.name);

  return apiRest.post(
    'antifraud/fraud/match-damaged-package-image/',
    formData,
    {
      headers: {
        ...(await headers()),
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

const uploadFileToS3 = ({ url, fields, file, method, contentType }) => {
  let body;
  let headersWithContentType;

  if (contentType) {
    headersWithContentType = new Headers({
      'Content-Type': contentType
    });
  }
  if (fields) {
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);

    body = formData;
  } else {
    body = file;
  }

  return fetch(url, {
    method,
    mode: 'cors',
    body,
    headers: headersWithContentType
  });
};

const getLabel = async ({ baseRoutingCode, itemBarcode, labelType }) => {
  return apiRest.get(
    `/wms/api/v1/printer/${baseRoutingCode}/${itemBarcode}/${labelType}/label`,
    {
      headers: await headers()
    }
  );
};

const updateVehicleUnitLoad = async ({ vehicleType, licensePlate }) => {
  const requestBody = {
    license_plate: licensePlate,
    selected_transport_type: vehicleType
  };
  return apiRest.post(`/wms/api/v1/update-vehicle-unit-load`, requestBody, {
    headers: await headers()
  });
};

const custodyExchange = async (
  licensePlate,
  barcodes,
  operationType,
  selectedDistributionCenterId
) => {
  return apiRest.post(
    'wms/api/v1/custody-exchange',
    {
      driver_license_plate: licensePlate,
      barcodes,
      operation_type: operationType
    },
    {
      headers: await headers(selectedDistributionCenterId)
    }
  );
};

export {
  getOrCreateUnitLoad,
  identifyPackagesToReceive,
  movePackagesForCustodyTransfer,
  caraCrachaForDriver,
  getDestinationStatusPplt,
  sortUnitLoad,
  movePackage,
  movePackagesBulk,
  dispatchBag,
  unitize,
  getSortingContext,
  startTransfer,
  getUnitLoad,
  getUnitLoadMetrics,
  unitloadStartProcessingDeadlineDates,
  logLogicalMismatches,
  deliverBagRecord,
  pickupPhotoComparison,
  postProcessPhotoComparison,
  physicalEvidenceStatuses,
  editPackageStatus,
  cognitoDistributionCenters,
  getDistributeInfo,
  searchTransfers,
  addBagToTransfer,
  getReturnCauseList,
  logActivityTracking,
  getInfoFromPackageIdentifier,
  getLoggiLabel,
  getPostingCards,
  createPackageDirectionChange,
  getOrCreateRedispatchLabel,
  getTrips,
  addCargoToTransferByTrip,
  cancelTrip,
  getTripCargo,
  startTripTransfers,
  removeCargo,
  getContentDeclaration,
  getTripStats,
  declarePackageDamage,
  getDeclarePackageDamage,
  validDamagedPackageDeclaration,
  matchImageWithPackage,
  declareDamagedPackageV2,
  declareDamagedPackageV3,
  postCancelAllocation,
  generateDamagedPackagePresignedUrlsForUpload,
  uploadFileToS3,
  distributionUnitLoad,
  getNetworkDestinations,
  distributionAddCargo,
  getDistributionUnitLoadCargo,
  startTransfers,
  removeDistributionUnitLoadCargo,
  cancelDistribution,
  getLabel,
  updateVehicleUnitLoad,
  custodyExchange
};
export default apiRest;
